.animated-progress {
    width: auto;
    height: 30px;
    border-radius: 16px;
    /*margin: 20px 10px;*/
    border: 1px solid #000;
    overflow: hidden;
    position: relative;
}

.animated-progress .progress {
    height: 100%;
    display: block;
    width: 0;
    line-height: 30px;
    position: absolute;
    text-align: end;
    padding-right: 5px;
}

.progress-span {
    color: #000;
    position: absolute;
    z-index: 2;
    display: block;
    font-size: .8rem;
    margin-left: 10px;
    /*margin-top: auto;*/
    /*margin-bottom: auto;*/
    /*height: 100%;*/
    line-height: 30px;
}

/*.progress-blue .progress {*/
/*    background-color: blue;*/
/*}*/
/*.progress-green span {*/
/*    background-color: #639fff;*/
/*}*/
/*.progress-purple span {*/
/*    background-color: indigo;*/
/*}*/
/*.progress-red span {*/
/*    background-color: red;*/
/*}*/

.up {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medium {
    font-weight: 500;
    font-size: 1rem;
}

.light {
    font-weight: 300;
    font-size: .85rem;
}

.light2 {
    font-weight: 300;
    font-size: .8rem;
}

.title {
    display: flex;
    align-items: center;
    gap: 5px;
}

.activity {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.icon {
    width: 30px;
}