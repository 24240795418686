@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700;900&display=swap');

head {
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.progress-bar {
  border: 1px solid #ccc!important;
  border-radius: 3px;
  position: relative;
}

.fill {
  position:absolute;
  color: #000!important;
  background-color: #9e9e9e!important;
}


.activities {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-bottom: 10px;
}

.container {
  margin: auto;
  max-width: 800px;
}

.heading {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.subheading {
  margin-top: 0;
  font-weight: 500;
  font-size: 1.25rem;
}


.update {
  font-size: .9rem;
  font-weight: 300;
}

.lastActivities {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 60px;
  grid-column-gap: 50px;
  /*grid-row-gap: 20px;*/
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .container {
    margin: 0 10px;
    max-width: 800px;
  }

  .lastActivities {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 550px) {
  .lastActivities {
    grid-template-columns: repeat(1, 1fr);
  }
}